exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-kitchen-and-bathroom-plumbing-renovations-js": () => import("./../../../src/pages/services/kitchen-and-bathroom-plumbing-renovations.js" /* webpackChunkName: "component---src-pages-services-kitchen-and-bathroom-plumbing-renovations-js" */),
  "component---src-pages-services-residential-plumbing-js": () => import("./../../../src/pages/services/residential-plumbing.js" /* webpackChunkName: "component---src-pages-services-residential-plumbing-js" */)
}

